.bookshadow{
  box-shadow: 1px 0px 11px 0px rgba(21, 21, 21, 0.75);
-webkit-box-shadow: 1px 0px 11px 0px rgba(21, 21, 21,0.75);
-moz-box-shadow: 1px 0px 11px 0px rgba(21, 21, 21,0.75);
-webkit-transition: -webkit-transform 0.2s;
transition: -webkit-transform 0.2s;
transition: transform 0.2s;
transition: transform 0.2s, -webkit-transform 0.2s; /* Animation */
}

.bookshadow:hover{
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

ul a{
  color: #8b939c;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.mailinglist:focus {
outline: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.prod-btn{
  border: none;
  border-bottom: 10px solid green;
}

.prod-btn:hover{
  height: 44px;
  margin-top: 4px;
  border-bottom: 6px solid green;
  transition: all .1s ease-in;
}

.footerLink a:hover{
  color: #b9cfe9;
}
