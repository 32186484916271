label{
  text-align: left;
}

.errorMessage{
  color: #BE5F4B;
  font-size: 15px;
}
.errPlacement{
  position: relative;
  left: 2px;
  top: -14px;
}

.inputContact[type=text], select, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type=submit] {
  background-color: #04AA6D;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  border-bottom: 8px solid green;
  height: 47px;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: #45a049;
  height: 44px;
  margin-top: 4px;
  border-bottom: 6px solid green;
  transition: all .1s ease-in;
}


input[type=submit]:disabled {
  background-color: #7f9781;
  height: 44px;
  margin-top: 4px;
  border-bottom: 6px solid rgb(67, 79, 67);
  transition: all 0.1s ease-in;
  cursor: not-allowed; /* Add this to change the cursor to 'not-allowed' */
  /* Add any additional styles you want for a disabled, hovered button */
}

.container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}

.submit-hover {
  background: red;
}