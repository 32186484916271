.butterfly-rotate {
    position: relative;
    width: 2.5rem;
    margin: 0 auto;
    right: 0;
    animation: rotate 3s -2.25s ease-in-out infinite;
  }
  
  @keyframes box-move {
    0% {
      transform: translateX(-1rem);
    }
    50% {
      transform: translateX(1.4rem);
    }
    100% {
      transform: translateX(-1rem);
    }
  }
  .butterfly-box {
    animation: box-move 3s ease-in-out infinite;
  }
  
  @keyframes move {
    0% {
      transform: translateY(0.2rem);
    }
    100% {
      transform: translateY(0.6rem);
    }
  }
  .butterfly {
      /* position: relative;
          left: -21px;
    top: -117px; */
    display: flex;
    justify-content: center;
    align-items: flex-end;
    animation: move 3s infinite alternate;
    opacity: 0.8;
  }
  .butterfly .main {
    width: 0.3rem;
    height: 0.6rem;
    margin: 0 0.1rem 0.35rem;
    box-sizing: border-box;
    border-top: 0.6rem solid #000;
    border-left: 0.15rem solid transparent;
    border-bottom: 0;
    border-right: 0.15rem solid transparent;
    opacity: 0.7;
  }
  .butterfly .wing {
    width: 0.8rem;
    height: 1.4rem;
    position: relative;
    opacity: 1;
  }
  .butterfly .wing::before, .butterfly .wing::after {
    content: "";
    display: inline-block;
    box-sizing: border-box;
  }
  .butterfly .wing-right {
    transform-origin: left center;
    transform: rotateY(75deg);
    animation: flap 0.35s ease-in-out infinite;
  }
  .butterfly .wing-right::before {
    border-left: 0;
    border-right: 0.8rem solid #e6d260;
    border-bottom: 0.4rem solid transparent;
    border-top: 0.6rem solid transparent;
  }
  .butterfly .wing-right::after {
    position: absolute;
    bottom: 0;
    left: 0;
    border-right: 0;
    border-left: 0.5rem solid #e6d260;
    border-bottom: 0.3rem solid transparent;
    border-top: 0.3rem solid transparent;
    transform-origin: top left;
    transform: rotate(-5deg);
  }
  .butterfly .wing-left {
    transform-origin: right center;
    transform: rotateY(75deg);
    animation: flap 0.35s ease-in-out infinite;
  }
  .butterfly .wing-left::before {
    border-right: 0;
    border-left: 0.8rem solid #e6d260;
    border-bottom: 0.4rem solid transparent;
    border-top: 0.6rem solid transparent;
  }
  .butterfly .wing-left::after {
    position: absolute;
    bottom: 0;
    right: 0;
    border-left: 0;
    border-right: 0.5rem solid #e6d260;
    border-bottom: 0.3rem solid transparent;
    border-top: 0.3rem solid transparent;
    transform-origin: top right;
    transform: rotate(5deg);
  }
  
  @keyframes flap {
    0% {
      transform: rotateY(0deg);
    }
    45% {
      transform: rotateY(75deg);
    }
    100% {
      transform: rotateY(0deg);
    }
  }