html,
body,
.ag-format-container,
.ag-products {
  height: 100%;
}

.ag-format-container {
  width: 1142px;
  margin: 0 auto;;
}

.ag-products {

  overflow: hidden;

  position: relative;
}

.ag-flower {
  -webkit-animation-name: an-grow;
  animation-name: an-grow;

  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-delay: 0s;
  animation-delay: 0s;

  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  
  z-index: 81;
  position: absolute;
  top: 16px;
}
.ag-flower span {
  height: 800px;
  width: 8px;
  background: #b4d28c;

  z-index: 5;
  position: absolute;
}

.ag-flower span:before {
  content: "";
  display: block;
  height: 48px;
  width: 48px;
  background: #a3c872;

  box-shadow: 0 0 0 3px #a1c76f inset;

  border-radius: 0 32px;

  -webkit-transform: rotateY(140deg);
  transform: rotateY(140deg);

  -webkit-transform-origin: right;
  transform-origin: right;

  -webkit-animation-name: an-turn-left;
  animation-name: an-turn-left;

  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-delay: 4s;
  animation-delay: 4s;

  -webkit-animation-duration: 4s;
  animation-duration: 4s;

  z-index: 0;
  position: absolute;
  top: 80px;
  left: -48px;
}
.ag-flower span:after {
  content: "";
  display: block;
  height: 48px;
  width: 48px;
  background: #a8cb7a;

  box-shadow: 0 0 0 3px #a1c76f inset;

  border-radius: 32px 0;

  -webkit-transform: rotateY(-140deg);
  transform: rotateY(-140deg);

  -webkit-transform-origin: left;
  transform-origin: left;

  z-index: 0;
  position: absolute;
  top: 16px;
  left: 8px;
}

.ag-flower-1 {
  height: 144px;
  width: 144px;
  margin-top: 336px;
  border: 16px solid #f8c475;
  background-color: #f3f875;

  opacity: 0;

  box-shadow: 0 0 0 3px #f7bc62, 0 0 0 3px #f5f988 inset;

  border-radius: 320px;

  -webkit-animation-delay: 0s;
  animation-delay: 0s;

  left: 5%;
}
.ag-flower-1 span {
  top: 163px;
  left: 68px;
}
.ag-flower-1 span:before {
  -webkit-animation-name: an-turn-right;
  animation-name: an-turn-right;

  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-delay: 0s;
  animation-delay: 0s;

  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}
.ag-flower-1 span:after {
  -webkit-animation-name: an-turn-left;
  animation-name: an-turn-left;

  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-delay: 0s;
  animation-delay: 0s;

  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.ag-flower-2 {
  height: 80px;
  width: 80px;
  margin-top: 192px;
  border: 16px solid #f87575;
  background-color: #f98888;

  opacity: 0;

  box-shadow: 0 0 0 3px #f8c475, 0 0 0 3px #b9dffb inset;

  border-radius: 480px;

  -webkit-animation-delay: .3s;
  animation-delay: .3s;

  left: 25%;
}
.ag-flower-2 span {
  top: 99px;
  left: 36px;
}
.ag-flower-2 span:before {
  -webkit-animation-name: an-turn-right;
  animation-name: an-turn-right;

  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-delay: .3s;
  animation-delay: .3s;

  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}
.ag-flower-2 span:after {
  -webkit-animation-name: an-turn-left;
  animation-name: an-turn-left;

  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-delay: .4s;
  animation-delay: .4s;

  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.ag-flower-3 {
  height: 112px;
  width: 112px;
  margin-top: 304px;
  border: 11px solid #ff89b3;
  background-color: #d9abff;

  opacity: 0;

  box-shadow: 0 0 0 3px #ff75a6, 0 0 0 3px #e2bfff inset;

  border-radius: 320px;

  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;

  right: 25%;
}
.ag-flower-3 span {
  top: 126px;
  left: 52px;
}
.ag-flower-3 span:before {
  -webkit-animation-name: an-turn-right;
  animation-name: an-turn-right;

  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;

  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}
.ag-flower-3 span:after {
  -webkit-animation-name: an-turn-left;
  animation-name: an-turn-left;

  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-delay: 2s;
  animation-delay: 2s;

  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.ag-flower-4 {
  height: 176px;
  width: 176px;
  margin-top: 144px;
  border: 19px solid #f8c475;
  background-color: #f87575;

  opacity: 0;

  box-shadow: 0 0 0 3px #f7bc62, 0 0 0 3px #f98888 inset;

  border-radius: 400px;

  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;

  right: 5%;
}
.ag-flower-4 span {
  top: 198px;
  left: 84px;
}
.ag-flower-4 span:before {
  -webkit-animation-name: an-turn-right;
  animation-name: an-turn-right;

  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;

  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}
.ag-flower-4 span:after {
  -webkit-animation-name: an-turn-left;
  animation-name: an-turn-left;

  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-delay: 2.4s;
  animation-delay: 2.4s;

  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

@-webkit-keyframes an-grow {
  from {
    top: 240px;
  }
  to {
    top: 16px;
    opacity: 1;
  }
}

@keyframes an-grow {
  from {
    top: 240px;
  }
  to {
    top: 16px;
    opacity: 1;
  }
}

@-webkit-keyframes an-turn-right {
  from {
    -webkit-transform: rotateY(-140deg);
    transform: rotateY(-140deg);
  }
  to {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
}

@keyframes an-turn-right {
  from {
    -webkit-transform: rotateY(-140deg);
    transform: rotateY(-140deg);
  }
  to {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
}

@-webkit-keyframes an-turn-left {
  from {
    -webkit-transform: rotateY(140deg);
    transform: rotateY(140deg);
  }
  to {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
}

@keyframes an-turn-left {
  from {
    -webkit-transform: rotateY(140deg);
    transform: rotateY(140deg);
  }
  to {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
}

@media only screen and (max-width: 767px) {
  .ag-format-container {
    width: 96%;
  }

}

@media only screen and (max-width: 639px) {

}

@media only screen and (max-width: 479px) {

}

@media (min-width: 768px) and (max-width: 979px) {
  .ag-format-container {
    width: 750px;
  }

}

@media (min-width: 980px) and (max-width: 1161px) {
  .ag-format-container {
    width: 960px;
  }

}
