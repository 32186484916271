
a {
  text-decoration: none;
}

.logo{
    font-family: 'Lato', sans-serif;
    font-style: italic;
}

.menu {
  /* position: absolute;
  top: 50%;
  left: 50%; */
  transform: translate(0%, 0%);
  text-align: center;
}

.menu__link {
  display: inline-block;
  color: #2d6c90;
  text-decoration: none;
  position: relative;
  padding: 30px 20px;
  font-family: 'Muli', sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 1;
  letter-spacing: 0.040em;
}

.menu__link svg {
  fill: none;
  stroke: #70aec9;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke-dasharray: 338;
  stroke-dashoffset: 338;
  stroke-linecap: round;
  position: absolute;
  top: 50%;
  left: 50%;
  /* width: 100px; */
  width: calc(80% + 15px);
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: stroke-dashoffset 0s 0.2s, opacity 0.2s;
  z-index: -1;
}

.menu__link--active svg {
  stroke: #85cd85;
}

.menu__link--active svg,
.menu__link:hover svg {
  stroke-dashoffset: 0;
  opacity: 1;
  transition: opacity 0s, stroke-dashoffset 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}